import { Link } from "gatsby"
import React from "react"

import {
  Text,
  TextType,
  TextFontSize,
  TextFontWeight,
} from "../components/Text"

import "./page.styles.scss"

const NewsletterPolicy = () => {
  return (
    <div className={"page"}>
      <Text
        type={TextType.H1}
        size={TextFontSize.huge}
        weight={TextFontWeight.bold}
      >
        Newsletter policy
      </Text>

      <Text
        type={TextType.H2}
        size={TextFontSize.big}
        weight={TextFontWeight.bold}
      >
        Terms of using the Newsletter
      </Text>
      <ul className={"page__list page__list--decimal"}>
        <li>
          As part of the Newsletter service, information is sent by the Service
          Provider via e-mail to the e-mail address provided by the User
          (hereinafter referred to as the "Newsletter"). The Newsletter is provided
          free of charge, for an indefinite period.
        </li>
        <li>
          To use the Newsletter you need
          <ul className={"page__list"}>
            <li>A device which is capable of opening your email inbox.</li>
            <li>Active email inbox</li>
          </ul>
        </li>
        <li>
          The Newsletter contains in particular
          <ul className={"page__list"}>
            <li>information about a new articles on our Website</li>
            <li>news from the world of programming and the IT industry</li>
            <li>learning content</li>
            <li>
              marketing content (for example about our courses or other
              products)
            </li>
          </ul>
        </li>
        <li>
          Each Newsletter contains:
          <ul className={"page__list"}>
            <li>
              information about the Service Provider as the sender of the
              Newsletter
            </li>
            <li>"Subject" specifying the content of the Newsletter</li>
            <li>
              information on how to unsubscribe from the Newsletter or change
              the User's email address to which the Newsletter is sent
            </li>
          </ul>
        </li>
        <li>
          To start receiving Newsletter you need to accept <Link to='/newsletter-policy'>Newsletter Policy</Link> and <Link to='/privacy-policy'>Privacy Policy</Link> and click the “Subscribe” button.
        </li>
        <li>
          We reserve the right to stop sending the Newsletter at any time
          without giving a reason
        </li>
        <li>
          The User may at any time, without giving a reason and without
          incurring costs, resign from the Newsletter by following the
          information placed in the footer of each Newsletter.
        </li>
      </ul>

      <Text
        type={TextType.H2}
        size={TextFontSize.big}
        weight={TextFontWeight.bold}
      >
        Final Provisions
      </Text>
      <ul className={"page__list page__list--decimal"}>
        <li>
          The Service Provider reserves the right to change the Regulations. The
          Service Provider will inform Users about the content of changes to the
          Regulations by posting on the website hello-js.com/newsletter-policy
          information about the change to the Regulations or/and by notifying
          the User about the change in the Regulations by sending via the
          Newsletter, to the e-mail address provided during registration.
          Notification of the change in the Regulations will take place at least
          14 calendar days before the introduction of the amended Regulations.
        </li>
        <li>
          Users can resign from the Newsletter before the amended Regulations
          date if they do not agree to the Regulations changes.
        </li>
        <li>
          The Newsletter Policy is always accessible at <Link to="/newsletter-policy">newsletter-policy</Link>
        </li>
      </ul>

      <Text
        type={TextType.H2}
        size={TextFontSize.big}
        weight={TextFontWeight.bold}
      >
        Contact
      </Text>
      <Text type={TextType.PARAGRAPH}>
        In case of any questions or concerns regarding this Policy or the
        practices of this Website, we encourage you to contact us at:
      </Text>
      <Text type={TextType.PARAGRAPH}>
        Michał Rożenek
        <br />
        contact.hellojs@gmail.com
        <br />
        <br />
        <i>Last updated on 02/01/2023</i>
      </Text>
    </div>
  )
}

export default NewsletterPolicy
